// src/index.js
import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
// import App from './App';
const App = lazy(() => import('./App'));
import { CartProvider } from './contexts/CartContext';
import { AuthProvider } from './contexts/AuthContext';
import { ConfigProvider } from 'antd';
import { CurrencyProvider } from './contexts/CurrencyContext';
import { OrderProvider } from './contexts/OrderContext';
import { BrowserRouter } from 'react-router-dom';
import { initGA, logPageView } from './utils/analytics';
import { CheckoutProvider } from './contexts/CheckoutContext';
import { theme } from 'antd';

const container = document.getElementById('root');
const root = createRoot(container);

const GA_MEASUREMENT_ID = 'G-S1HBJK899H'; // Replace with your actual Measurement ID
initGA(GA_MEASUREMENT_ID);

const originalError = console.error;
console.error = (...args) => {
  if (args[0].includes('Warning: ')) {
    return;
  }
  originalError.apply(console, args);
};

const { defaultAlgorithm, defaultSeed } = theme;

const rainbowTheme = {
  algorithm: [defaultAlgorithm],
  token: {
    ...defaultSeed,
    colorPrimary: '#FF6B6B', // Red
    colorSuccess: '#4ECB71', // Green
    colorWarning: '#FFA400', // Orange
    colorError: '#9B59B6', // Purple
    colorInfo: '#3498DB', // Blue
    fontSize: 20,
    fontSizeSM: 18,
    fontSizeLG: 32,
  },
  components: {
    message: {
      top: 24,
    },
    Button: {
      colorPrimary: '#FF6B6B',
      algorithm: true,
    },
    Input: {
      colorPrimary: '#4ECB71',
      algorithm: true,
    },
    Select: {
      colorPrimary: '#FFA400',
      algorithm: true,
    },
    Checkbox: {
      colorPrimary: '#9B59B6',
      algorithm: true,
    },
    Radio: {
      colorPrimary: '#3498DB',
      algorithm: true,
    },
  },
};

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ConfigProvider theme={rainbowTheme}>
        <AuthProvider>
          <CheckoutProvider>
            <CartProvider>
              <CurrencyProvider>
                <OrderProvider>
                  <App />
                </OrderProvider>
              </CurrencyProvider>
            </CartProvider>
          </CheckoutProvider>
        </AuthProvider>
      </ConfigProvider>
    </BrowserRouter>
  </React.StrictMode>
);
