// OrderContext.js
import React, { createContext, useContext, useState, useCallback } from 'react';
import { getOrders } from '../services/API';
import { message } from 'antd';

const OrderContext = createContext();

export function OrderProvider({ children }) {
  const [orders, setOrders] = useState({});
  const [orderList, setOrderList] = useState([]);
  const [isLoadingOrders, setIsLoadingOrders] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const fetchOrders = useCallback(async () => {
    if (!hasMore || isLoadingOrders) {
      return false;
    }
    console.log('Fetching orders');
    setIsLoadingOrders(true);
    try {
      const response = await getOrders(page);
      const newOrders = response?.data?.orders;
      if (!newOrders || newOrders.length === 0) {
        return false;
      }
      setOrders((prevOrders) => {
        const updatedOrders = { ...prevOrders };
        newOrders.forEach((order) => {
          updatedOrders[order.id] = order;
        });
        return updatedOrders;
      });
      // Set the orderlist and ensure there are no dupes.
      setOrderList((prevList) => {
        const newList = [...prevList];
        newOrders.forEach((order) => {
          if (!newList.find((item) => item.id === order.id)) {
            newList.push(order);
          }
        });
        return newList;
      });
      setPage((prevPage) => prevPage + 1);
    } catch (error) {
      message.error('There was an issue fetching orders');
      console.error('Error fetching orders:', error);
    }
  }, [hasMore, page]);

  return (
    <OrderContext.Provider
      value={{
        orders,
        orderList,
        isLoadingOrders,
        setIsLoadingOrders,
        hasMore,
        fetchOrders,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
}

export const useOrderContext = () => useContext(OrderContext);
