import axios from 'axios';

const defaultConfig = {
  baseURL: process.env.API_URL,
  timeout: 600000 * 5, // 5 minutes
  headers: {
    setContentType: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
};

function createApi(customConfig) {
  const config = { ...defaultConfig, ...customConfig };
  return axios.create(config);
}

const api = createApi();
const authApi = createApi({
  headers: {
    Authorization: localStorage.getItem('token'),
  },
  withCredentials: true,
});

// Request interceptor
authApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Auth
export const login = (email, password) =>
  api.post('/users/login', { email, password });
export const register = (email, password) =>
  api.post('/users/register', { email, password });
export const logout = () => authApi.post('/users/logout');
export const updatePassword = (password) =>
  authApi.put('/users/password', { password });
export const refreshToken = (refreshToken) =>
  authApi.post('/users/refresh-token', { refreshToken });

// Products
export const getProducts = (page = 1, limit = 10) =>
  api.get(`/products?page=${page}&limit=${limit}`);
export const getProduct = (id) => api.get(`/products/${id}`);
export const searchProducts = (query) => api.get(`/products/search?q=${query}`);

// Orders
export const getShippingQuotes = (orderData) =>
  authApi.post('/shipping', orderData);
export const createOrder = (orderData) => authApi.post('/orders', orderData);
export const getOrders = (page = 1, limit = 10) =>
  authApi.get(`/orders?page=${page}&limit=${limit}`);
export const getOrder = (id) => authApi.get(`/orders/${id}`);
export const updateOrder = (id, orderData) =>
  authApi.put(`/orders/${id}`, orderData);
export const cancelOrder = (id) => authApi.post(`/orders/${id}/cancel`);

// Discounts
export const applyDiscount = (code, amount) =>
  api.post('/discount/apply', { code, amount });

// User profile
export const getUsers = () => authApi.get('/users');
export const getUserProfile = () => authApi.get('/users/profile');
export const updateUserProfile = (userData) =>
  authApi.put('/users/profile', userData);

export const validateToken = () => authApi.get('/users/validate');

// Addresses
export const getSavedAddresses = () => authApi.get('/users/addresses');
export const saveUserAddress = (addressData) =>
  authApi.post('/users/addresses', addressData);

export const getUserLocationAndCurrency = () => api.get('/users/location');

export const getExchangeRates = () => authApi.get('/currency');

export const getCountryList = () => authApi.get('/country');
export const getRegionList = (countryCode) =>
  authApi.get(`/country/${countryCode}`);

// Error handling helper
export const handleApiError = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.error('Data:', error.response.data);
    console.error('Status:', error.response.status);
    console.error('Headers:', error.response.headers);
    return error.response.data.message || 'An error occurred';
  } else if (error.request) {
    // The request was made but no response was received
    console.log('Request:', error);
    return 'No response received from server';
  } else {
    // Something happened in setting up the request that triggered an Error
    console.error('Error:', error.message);
    return 'Error in setting up the request';
  }
};

export default api;
