// src/contexts/AuthContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import {
  login as apiLogin,
  register as apiRegister,
  validateToken as apiValidateToken,
} from '../services/API';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isLoading && !token) {
      getAuth();
    }
  }, [isLoading, token]);

  /**
   * Get authentication data from local storage.
   */
  function getAuth() {
    const _token = localStorage.getItem('token');
    let _user = localStorage.getItem('user');
    if (_user) {
      try {
        _user = JSON.parse(_user);
      } catch (error) {
        console.error('Failed to parse user data: ', error);
      }
    }
    if ([_token, _user].filter(Boolean).length === 2) {
      setData(_token, _user);
      validateAuth(_token, _user);
    }
    setIsLoading(false);
  }

  async function validateAuth(_token, _user) {
    try {
      const response = await apiValidateToken(_token);
      if (response.data.user.id === _user.id) {
        setUser(response.data.user);
      }
      if (!response.data.user) {
        logout();
      }
      setIsLoading(false);
    } catch (error) {
      console.log('Token validation failed.', error);
      logout();
    }
  };

  function setData(_token, _user) {
    setToken(_token);
    localStorage.setItem('token', _token);
    setUser(_user);
    localStorage.setItem('user', JSON.stringify(_user));
  }

  const login = async (email, password) => {
    try {
      const response = await apiLogin(email, password);
      setData(response.data.token, response.data.user);
      return response.data;
    } catch (error) {
      console.error('Login failed:', error);
      return false;
    }
  };

  const register = async (email, password) => {
    try {
      const response = await apiRegister(email, password);
      setData(response.data.token, response.data.user);
      return response.data;
    } catch (error) {
      console.error('Registration failed:', error);
      return false;
    }
  };
  //
  const logout = () => {
    localStorage.clear();
    setToken(null);
    setUser(null);
  };

  return (
    <AuthContext.Provider
      value={{ user, token, login, register, logout, isLoading }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
