// src/contexts/CheckoutContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import getStripe from '../services/Stripe';

const CheckoutContext = createContext();

export const useCheckout = () => useContext(CheckoutContext);

export const stripe = getStripe();

export const CheckoutProvider = ({ children }) => {
  const [checkoutData, setCheckoutData] = useState(() => {
    const savedData = localStorage.getItem('checkoutData');
    return savedData
      ? JSON.parse(savedData)
      : {
        billingAddress: null,
        shippingAddress: null,
        selectedShipping: null,
        useBillingForShipping: false,
        currentStep: 0,
      };
  });

  // Validate that the checkout data has one valid address.
  const validateAddress = (_address = null) => {
    // If _address is provided, validate that address.
    if (_address) {
      const requiredFields = ['name', 'addressLine1', 'city', 'country', 'state', 'postal_code'];
      if (!requiredFields.every((field) => _address[field])) {
        return false;
      }
      return true;
    }
    // Shipping address is optional and billing address checkbox (useBillingForShipping) governs this.
    const requiredFields = ['name', 'addressLine1', 'city', 'country', 'postal_code'];
    const billingAddress = checkoutData.billingAddress || {};
    const shippingAddress = checkoutData.shippingAddress || {};
    if (!billingAddress) {
      return false;
    }
    if (!shippingAddress && !checkoutData.useBillingForShipping) {
      return false;
    }
    if (!requiredFields.every((field) => billingAddress[field])) {
      return false;
    }
    if (!requiredFields.every((field) => shippingAddress[field])) {
      return false;
    }
    return true;
  }

  const updateCheckoutData = (newData) => {
    setCheckoutData((prevData) => ({ ...prevData, ...newData }));
  };

  const clearCheckoutData = () => {
    setCheckoutData({
      billingAddress: null,
      shippingAddress: null,
      selectedShipping: null,
      useBillingForShipping: false,
      currentStep: 0,
    });
  };

  return (
    <CheckoutContext.Provider
      value={{ checkoutData, updateCheckoutData, clearCheckoutData, validateAddress }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};
