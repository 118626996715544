// src/services/stripe.js
import { loadStripe } from '@stripe/stripe-js';

export const live_publishable_key =
  'pk_live_51PYqZ1Khhxxer4SVVEQlCJSQr5buUWHzQG8rlLROXlnI1mI1Tldzqj2yVscVL9rMynfE5mRkuzfTJh4u14zn1XBR0007VaAALa';
export const test_publishable_key =
  'pk_test_51PYqZ1Khhxxer4SVFDqD9RNjqrCGi2XEImDdQHGzdX9bHlVpmwLpFVQfqaUd8oi3ZYv5iXNIAZUT20VjyOEtH91P00GUyLiulg';

export default function getStripe() {
  let stripe;
  if (!stripe) {
    stripe = loadStripe(live_publishable_key);
  }
  return stripe;
}
