// src/contexts/CurrencyContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import { getExchangeRates, getUserLocationAndCurrency } from '../services/API';
import { useAuth } from './AuthContext';

const CountryCurrencyMap = {
  US: 'USD',
  CA: 'CAD',
  GB: 'GBP',
  // EU: 'EUR',
};

const AvailableCurrencies = ['CAD', 'USD', 'GBP'];

const CurrencyContext = createContext({
  currentCurrency: 'CAD',
  availableCurrencies: [],
  exchangeRates: {},
  setCurrentCurrency: () => { },
  convertPrice: () => { },
});

export function getCurrencyForCountry(countryCode) {
  return CountryCurrencyMap[countryCode] || 'CAD';
}

export function useCurrencyContext() {
  return useContext(CurrencyContext);
}

export function CurrencyProvider({ children }) {
  const { user, token } = useAuth();
  const [currentCurrency, setCurrentCurrency] = useState('CAD');
  const [availableCurrencies, setAvailableCurrencies] =
    useState(AvailableCurrencies);
  const [exchangeRates, setExchangeRates] = useState({});
  const [userCountry, setUserCountry] = useState(null);
  const [isCurrencyLoading, setCurrencyIsLoading] = useState(true);

  useEffect(() => {
    if (!user) {
      setCurrencyIsLoading(false);
      return;
    }
    // const fetchData = async () => {
    //   setIsLoading(true);
    //   await fetchUserLocationAndCurrency();
    //   await fetchExchangeRates();
    //   setIsLoading(false);
    // };
    // fetchData();
  }, [user]);

  const fetchUserLocationAndCurrency = async () => {
    try {
      const cachedCountry = localStorage.getItem('country');
      const cachedCurrency = localStorage.getItem('currency');

      if (cachedCountry && cachedCurrency) {
        setUserCountry(cachedCountry);
        setCurrentCurrency(cachedCurrency);
      } else {
        if (user && token) {
          const response = await getUserLocationAndCurrency();
          const { country, currency } = response.data;
          setUserCountry(country);
          setCurrentCurrency(currency);
          localStorage.setItem('currency', currency);
          localStorage.setItem('country', country);
        }
      }
    } catch (error) {
      console.error('Failed to fetch user location and currency:', error);
    }
  };

  const fetchExchangeRates = async () => {
    if (!user) return;

    const cachedRates = localStorage.getItem('exchangeRates');
    const cacheTimestamp = localStorage.getItem('exchangeRatesTimestamp');
    if (cachedRates && cacheTimestamp) {
      const now = new Date().getTime();
      const cacheAge = now - parseInt(cacheTimestamp);

      // Use cached rates if they're less than 24 hours old.
      if (cacheAge < 24 * 60 * 60 * 1000) {
        setExchangeRates(JSON.parse(cachedRates));
        return;
      }
    }

    try {
      const rates = await getExchangeRates();
      setExchangeRates(rates);
      localStorage.setItem('exchangeRates', JSON.stringify(rates));
      localStorage.setItem(
        'exchangeRatesTimestamp',
        new Date().getTime().toString(),
      );
    } catch (error) {
      console.error('Failed to fetch exchange rates:', error);
    }
  };

  const convertPrice = (
    price,
    fromCurrency = 'CAD',
    toCurrency = currentCurrency,
  ) => {
    if (fromCurrency === toCurrency) return price;
    if (!exchangeRates[fromCurrency] || !exchangeRates[toCurrency])
      return price;

    const basePrice = price / exchangeRates[fromCurrency];
    return basePrice * exchangeRates[toCurrency];
  };

  const formatPrice = (price, currency = currentCurrency) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
    }).format(price);
  };

  const value = {
    currentCurrency,
    setCurrentCurrency,
    setUserCountry,
    availableCurrencies,
    exchangeRates,
    convertPrice,
    formatPrice,
    userCountry,
    isLoading: isCurrencyLoading,
  };

  return (
    <CurrencyContext.Provider value={value}>
      {children}
    </CurrencyContext.Provider>
  );
}
